.absolute-button{
    position: absolute;
    top: -1.4rem;
    cursor: pointer;
}

.absolute-button--left{
    left: .4rem;
}
.absolute-button--right{
    right: .4rem;
}

.dots-container{
    position: absolute;
    top:0;
}

.gallery-no-data-container{
    height: 200px;
    background-color: black;
}

.dots{
    display: flex;
    flex-direction: row;    
}

.dots__dot{
    width: .6rem;
    height: .6rem;
    border-radius: .3rem;
    margin: .2rem;
    background-color: gray;
    cursor: pointer;
}

.dots__dot--active{
    background-color: rgb(87, 87, 225);
}

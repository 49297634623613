:root {
  --gray: rgb(88, 88, 88);
  --gray-soft: #282c341d;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p{
  margin-bottom: 0!important;
}


/* 
  background color

*/
.bg-gray{
  background-color:var(--gray);
}
.bg-gray-soft{
  background-color: var(--gray-soft);
}
.card-driving{
  background-color: var(--gray-soft);
  padding: 4px;
  border-radius: 4px;  
}
.card-driving > *{
  margin: 0;
}
/*Support classes*/

  .overflow-hidden{
    overflow:hidden;
  }

  .absolute-center{
    text-align: center;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }
  .absolute-button-center{
    top: 50%;
  }

  .transparent-layer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;  
    opacity: .2;
    pointer-events: none;
  }
  .transparent-layer-black{
    background-color: black;
  }
  .cursor-pointer{
    cursor: pointer;
  }

  .icon-container{
    height: 10px;
    width: 10px;
  }

  .border-accent{
    border-width: 1rem;
  }

  .row-minus{
    margin-top:-3rem!important;
  }
  .row-list{
    box-shadow: 0 0 2px 0 grey;
  }
  .frame-absolute{
    position: absolute;
    left: 0;
    top: 0;
  }

  .btn-link--active{

  }

/* COMPONENTS */

  .btn h1,.btn h2,.btn h3,.btn h4,.btn h5,.btn h6{
    margin-bottom: 0;
  }

  /* UserImage */
  .user-image{
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: var(--gray);
    color: white;
    box-shadow: 0rem 0rem 2px 0rem var(--gray);
  }
  .user-image--sm{
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  .user-image--md{
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  .user-image--review{
    
  }
    .user-image__container{
      
    }
      .user-image__container__icon{

      }
    
    .user-image__container > img{
    
    }
  /* cards */
  .card-plate{
    height: 100px;
  }
  .card-profile{
    border: 1px solid gray;
    border-bottom: none;
  }

  .crm-navbar{
    background-color: white;
    box-shadow: 0 0 2px 0 gray;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .btn-link{
    color: black!important;
    outline: none;
  }
  .card-message{
    background-color: var(--gray-soft);
    padding: .4rem;
    margin: .4rem;
    box-shadow: 0 0 2px 0 gray;
  }
  .card-title-value{
    border: 1px solid gray;
    padding: 2px;
    height: 100%;
    border-radius: .4rem;
  }

  .disabled{
    opacity: .6;
    pointer-events: none;
  }
  /* CRMModal */
  .crm-modal-container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: #000000b1;
    z-index: 100;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
    cursor: pointer;
  }
  .center-image-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
  }
  .crm-modal{
    min-width: 600px;        
    background-color: white;
    box-shadow: 0rem 0rem 2px 0rem var(--gray);
    position: relative;   
    margin-top: 100px; 
    margin-bottom: 50px;    
    z-index: 999;
    cursor: default;
  }
  .logo{
    width: 64px;
    height: 64px;
  }
  .logo-nav{
    width: 40px;
    height: 40px;
  }
  .no-margin{
    margin: 0;
  }
  .map-container{
    width: 100%;
    height: 300px;
  }
  .underline{
    text-decoration: underline;
  }
  .crm-modal--image{
    width: 800px;
    height: 600px;            
    position: relative;   
    margin-top: 80px; 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;
  }
    .crm-modal__close{
      width: 30px;
      height: 30px;
      position: absolute;
      top: -30px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .crm-modal__body{  
      width: 100%;
      height: 100%;      
    }
      .crm-modal__body__header{
        padding: 1rem;
      }
      .crm-modal__body__content{
        padding: 1rem;
      }
      .crm-modal__body__footer{
        padding: 1rem;
      }
  /* ReviewsItem */
  .review-image-fix{
    position: absolute;
    right: 10px;
    top: -20px;
  }

  .basic-list{
    min-height: 400px;
  }

  .gallery-frame{
    max-height: 400px;
    overflow: hidden;
  }
  .gallery-frame-image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    height: 100%;
  }

  